import { navigate } from "gatsby"

const ContentVisibility = {
	PUBLIC: 408_740_000,
	REGISTERED: 408_740_001,
	MEMBER: 408_740_002,
}

export const ContentGuardCheck = (contentVisibility, isAuthenticated, user) => {
	let userVisibility = ContentVisibility.PUBLIC

	if (user && user["https://ipweansw.org/isMember"]) {
		userVisibility = ContentVisibility.MEMBER
	}

	if (
		(contentVisibility === ContentVisibility.MEMBER &&
			userVisibility !== ContentVisibility.MEMBER) ||
		(contentVisibility === ContentVisibility.REGISTERED && !isAuthenticated)
	)
		return true

	return false
}

const ContentGuard = ({ loginCallBack, contentVisibility }) =>
	contentVisibility === ContentVisibility.REGISTERED ? (
		<div className="flex justify-center items-center mb-15 mt-5">
			<div className="p-4 border w-1/2">
				<h2 className="text-xl">
					This content is available to members and non-members.
				</h2>
				<p className="pt-2">
					To access please sign up or log in to the website. Signing up to the
					website is complimentary and gives access to other great content
					including articles and resources.
				</p>
				<p className="pt-2">
					<button
						type="button"
						onClick={loginCallBack}
						className="items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
					>
						Log In / Sign In
					</button>
				</p>
			</div>
		</div>
	) : (
		<div className="flex justify-center items-center mb-15 mt-5 ">
			<div className="p-4 border w-1/2">
				<h2 className="text-xl">This content is available to members only.</h2>
				<p className="pt-2">
					To access please login to the website or click become a member below.
					Becoming a member gives access to other great content including
					articles and resources and allows discount rates to all events and
					professional development workshops.
				</p>
				<p className="pt-2">
					<button
						type="button"
						onClick={loginCallBack}
						className="items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
					>
						Log In / Sign In
					</button>
				</p>
				<p className="pt-2">
					<button
						type="button"
						onClick={() => navigate("/membership")}
						className="items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
					>
						Become a Member today
					</button>
				</p>
			</div>
		</div>
	)

export default ContentGuard
