import Loader from "react-loader-spinner"

const Loading = ({ type }) => {
	if (type) {
		return <Loader type={type} color="#00BFFF" height={100} width={100} />
	}

	return <Loader type="TailSpin" color="#00BFFF" height={100} width={100} />
}

export default Loading
